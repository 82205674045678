import * as React from "react"
import Subscribe from "../../components/subscribe"
import Seo from "../../components/seo"
import Contactform from "../../components/contactForm"
import Layout from "../../components/layout"

const contactPage  = () => (
  <Layout siteTitle={"Contact WiN"} >

    <div className="relative bg-indigo-800">
      <div className="absolute inset-0">
        <img
          className="h-full w-full object-cover"
          src="https://images.unsplash.com/photo-1581094271901-8022df4466f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3870&q=80"
          alt=""
        />
        <div className="absolute inset-0 bg-indigo-800 mix-blend-multiply" aria-hidden="true" />
      </div>
      <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl lg:text-6xl">Contact Us</h1>
        <p className="mt-6  max-w-3xl text-xl text-indigo-100">
        If you have any questions, concerns or feedback, please reach out. We read every message and will get back to you as soon as possible. 
        </p>
      </div>
    </div>

    
    <div className="relative mx-auto max-w-7xl py-24 px-6 sm:py-32 lg:px-8">
    <Contactform />

    {/* email subscribe */}
    <Subscribe />
    </div>
  </Layout>
)

export const Head = () => <Seo title="Contact WiN" />

export default contactPage