import React, { useState } from "react";
import emailjs from 'emailjs-com';

export default function Contactform() {
  const [errorMessage, setErrorMessage] = useState("");
  const [submitStatus, setSubmitStatus] = useState(null); 

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    // Check if any field is empty
    let hasError = false;
    const payload = {}; // This object will contain the data to send

    formData.forEach((value, key) => {
      if (!value) {
        hasError = true;
        setErrorMessage("Please fill out all the fields.");
      }
      payload[key] = value; // Add the form values to the payload
    });

    if (!hasError) {
      // All fields are filled, submit the form using EmailJS
      emailjs.send('service_83dbufk', 'template_bmne38n', payload, 'suD6QwdB8SvvFZO89')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          // Optionally, you can add any success logic here
          setErrorMessage("");
          setSubmitStatus("success");
          event.target.reset();

          setTimeout(() => {
            setSubmitStatus(null);
          }, 5000);

        }, (error) => {
          console.log('FAILED...', error);
          setErrorMessage("There was an error submitting the form. Please try again.");
        });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="space-y-12">
        <div className="border-b border-gray-900/10 pb-12">
          <h2 className="text-xl font-semibold leading-7 text-gray-900">Please complete the form below:</h2>

          <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label htmlFor="first-name" className="block text-base font-medium leading-6 text-gray-900">
                First name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required // Make the field mandatory
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="last-name" className="block text-base font-medium leading-6 text-gray-900">
                Last name
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required // Make the field mandatory
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="email" className="block text-base font-medium leading-6 text-gray-900">
                Your email address
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required // Make the field mandatory
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label htmlFor="postal-code" className="block text-base font-medium leading-6 text-gray-900">
                Subject
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="subject-line"
                  id="subject-code"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  required // Make the field mandatory
                />
              </div>
            </div>
          </div>
        </div>

        <div className="col-span-full">
          <label htmlFor="about" className="block text-base font-medium leading-6 text-gray-900">
            Message
          </label>
          <div className="mt-2">
            <textarea
              id="about"
              name="about"
              rows={5}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              defaultValue={''}
              required // Make the field mandatory
            />
          </div>
        </div>

      </div>

      {errorMessage && (
        <div className="text-red-600 font-medium mt-2">
          {errorMessage}
        </div>
      )}

      {submitStatus === "success" && (
        <div className="bg-indigo-600 font-medium mt-2">
          Your message has been sent successfully!
        </div>
      )} 

      <div className="mt-6 mb-6 flex items-center justify-end gap-x-6">
        <button
          type="submit"
          className="rounded-md bg-indigo-600 px-8 py-2 text-base font-semibold text-white shadow-sm hover:bg-indigo-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Send
        </button>
      </div>
    </form>
  );
}
